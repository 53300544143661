import React, { useEffect, useState } from "react";
import { Box, Chip, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import "./DropDownTreeSelect.scss";
import TreeViewSearch from "./components/TreeViewSearch";

const DropDownTreeSelect = ({
  data,
  listId,
  setListId,
  disabled,
  onChangeCategories,
  clear,
}) => {
  const [itemsList, setItemsList] = useState([]);
  const [groupsList, setGroupsList] = useState([]);
  const [inputText, setInputText] = useState("");
  const [mappedData, setMappedData] = useState([]);

  useEffect(() => {
    if (clear) {
      setListId([]);
    }
  }, [clear]);

  useEffect(() => {
    const mapDataToTreeView = (data) => {
      return data.map((item) => ({
        id: item.id,
        label: item.name,
        children: item.subcategories ? mapDataToTreeView(item.subcategories) : null,
      }));
    };

    const mappedData = mapDataToTreeView(data);
    setMappedData(mappedData);

    mappedData
    .forEach((value) => {
      getAllNodeChild(value);
    });
  }, [data]);

  useEffect(() => {
      onChangeCategories(listId);
  }, [listId]);

  const getAllNodeChild = (node = null) => {
    if (node === null) return [];
    setItemsList((prev) => [...prev, { id: node.id, label: node.label }]);
    if (node?.children?.length > 0) {
      setGroupsList((prevState) => [...prevState, node.id]);
    }

    if (Array.isArray(node.children)) {
      node.children.forEach((node) => {
        getAllNodeChild(node);
      });
    }
    return itemsList;
  };

  const renderChips = () => {
    return (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {listId
          ? listId
              .map((value) => {
                return (
                  <Chip
                    className="custom-chip"
                    key={value}
                    sx={{ pt: 1.8, pb: 1.8 }}
                    onMouseDown={(event) => event.stopPropagation()}
                    onDelete={
                      disabled
                        ? undefined
                        : () => {
                            if (listId) {
                              const newList = listId.filter((v) => v !== value);
                              setListId(newList);
                            }
                          }
                    }
                    label={itemsList?.find((v) => v.id === value)?.label ?? ""}
                  />
                );
              })
          : null}
      </Box>
    );
  };

  return (
    <Autocomplete
      multiple
      fullWidth
      className={
        listId && listId.length ? "autocomplete" : "autocomplete-empty"
      }
      clearOnEscape={false}
      onChange={(event) => {
        !event.key && setListId([]);
        setInputText("");
      }}
      options={[mappedData]}
      disabled={disabled}
      value={listId}
      filterOptions={(v) => v}
      onBlur={() => setInputText("")}
      renderTags={() => renderChips()}
      disableCloseOnSelect
      inputValue={inputText}
      getOptionLabel={(option) => option.label || ""}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={() => (
        <TreeViewSearch
          data={mappedData}
          setListId={setListId}
          inputText={inputText}
          groupsList={groupsList}
          listId={listId}
        />
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(v) => setInputText(v.target.value)}
          placeholder="Buscar categoría"
        />
      )}
    />

  );
};
export default DropDownTreeSelect;
