import React, { useState, useEffect } from "react";
import { LocationService } from "services/LocationService";
import { BusinessNodeService } from "services/BusinessNodeService";
import { Checkbox, FormControl, Container, Chip, } from '@material-ui/core';
import { TextField, Autocomplete } from "@mui/material";

import { makeStyles } from '@material-ui/core/styles';

import SelectFilter from "components/MainApp/atoms/Filters/SelectFilter";
import "./styles.scss";

import filterData from "../data/filters";
import { isMobile } from "helpers/Mobile";
import DropDownTreeSelect from "./DropDownTreeSelect/DropDownTreeSelect";

let appliedEnabled = false;

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '250px',
    position: 'relative',
  },
  inputLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    backgroundColor: 'transparent',
    border: 'none',
  },
  select: {
    '& .MuiSelect-icon': {
      transform: 'translateY(90%)',
    },
  },
  outlinedInput: {
    paddingRight: '32px',
  },
  menuItemText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: isMobile ? 'calc(60% - 100px)' : '370px',
      minWidth: isMobile ? '288px' : '260px',
      maxWidth: isMobile ? '50vw' : '200px',
      transform: isMobile ? 'translateX(-2px)' :  'translateX(18px)',
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};

const AuditoriaFilterPanel = ({
  handleApplyProp,
  opened = false,
  stock=0,
  handleOpen,
  handleDeleteProp,
  handleSelectionChange,
  showFilters,
  filterProducts = [],
  handleSelectionChangeCategory,
  handleSelectionChangeProduct,
  selectedProducts = [],
  categoriesTree,
  clear,
}) => {
  const [filters, setFilters] = useState();
  const [msgNotZone, setMsgNotZone] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState(filterProducts);
  const [listId, setListId] = useState([]);

  useEffect(() => {
    setFilteredProducts(filterProducts);
  }, [filterProducts]);

  //Get Sucursales at the start
  useEffect(() => {
    BusinessNodeService.businessNodes({no_page: 1}).then(nodes => {
      nodes = nodes.map(n => {
        n.id = n.code;
        n.label = n.name;
        n.value = n.name;
        return n
      })

      filterData[0].options = nodes;
      filterData[1].options = [];
      filterData[2].options = [];
      setFilters(filterData);
    })
  }, []);

  const handleApply = (selectedOption) => {
    handleApplyProp(selectedOption);
  }

  //Save selection
  const handleSelection = (option, label) => {
    const index = filters.findIndex((f) => f.label === label);
    if(index === 0 ){
      getZones(option.code)
      const _selectedOptions = [option, {}, {}]
      setSelectedOptions(_selectedOptions)
      appliedEnabled = true;
    }

    if(index === 1 ) {
      appliedEnabled = true;
      getAreas(option.id)
      const _selectedOptions = [selectedOptions[0], option, {}]
      setSelectedOptions(_selectedOptions)
    }

    if(index === 2){
      appliedEnabled = true;
      const _selectedOptions = [...selectedOptions]
      _selectedOptions[2] = option
      setSelectedOptions(_selectedOptions)
    }
    handleSelectionChange(option);
  };

  const getZones = (node) => {
    LocationService.locationsAll({no_parent: "true", node: node}).then(locations => {
      setMsgNotZone(locations.length === 0 ? "No hay zonas para auditar en esta sucursal" : "");
      locations = locations.map(n => {
        n.label = n.name;
        n.value = n.name;
        return n
      })
      const newFilters = [...filters];

      newFilters[1].options = locations;
      newFilters[2].options = []
      setFilters(newFilters);
    })
  };
  const getAreas = (zone) => {
    LocationService.locationsAll({ parent: zone}).then(locations => {
      locations = locations.map(n => {
        n.label = n.name;
        n.value = n.name;
        return n
      })
      const newFilters = [...filters];

      newFilters[2].options = locations;
      setFilters(newFilters);
    })
  };

  const handleDelete = () => {
    setSelectedOptions([]);
    filterData[1].options = [];
    filterData[2].options = [];
    setFilters(filterData);
    appliedEnabled = false;
    handleDeleteProp();
  };

  // const handleRecursiveSelectionChange = (selectedItems, selectedCategories, productsBySelectedCategories) => {
  //   setSelectedRecursiveItems(selectedItems);
  //   handleChangeCategoriesProducts(selectedCategories, productsBySelectedCategories);
  // };

  return (
    <div className={`auditoria-filter-panel-container-out`}>
      {!opened && isMobile && (
        <div className={`auditoria-filter-panel-summary`}>
          <div className={`auditoria-filter-panel-summary-row`}>
            <div className={`auditoria-filter-panel-summary-filters`}>
              {filters?.map((filter, i) => {
                return (
                  selectedOptions[i]?.label && (
                    <div key={i}>
                      <div>
                        {filter.label} {selectedOptions[i].label}
                      </div>
                      <div className={"separator"}></div>
                    </div>
                  )
                );
              })}
            </div>
            <div
              className={`auditoria-filter-panel-summary-button`}
              onClick={handleOpen}
            >
              <i className={`icon-iconos_filtros`} />
            </div>
          </div>
          <div className={`auditoria-filter-panel-summary-row`}>
            <p className={"auditoria-filter-panel-summary-stock"}>
              Stock({stock})
            </p>
          </div>
        </div>
      )}
      {(opened || !isMobile) && (
        <div className={`auditoria-filter-panel-container`}>
          <div className={`auditoria-filter-panel-inputs`}>
            {filters?.map((filter, i) => {
              if (filter.options && filter.options?.length > 0) {
                return (
                  <SelectFilter
                    key={filter.label}
                    label={filter.label}
                    options={filter.options}
                    selectedOption={selectedOptions && selectedOptions[i]}
                    handleSelection={e => handleSelection(e, filter.label)}
                    placeholder={filter.placeholder}
                  ></SelectFilter>
                );
              }
            })}
            {msgNotZone && <div className="not-zones">{msgNotZone}</div>}
            {isMobile && (
              <div className={`auditoria-filter-panel-filtered-products`}>
                <div className={`auditoria-filter-categories-products`}>
                  {showFilters && categoriesTree && categoriesTree.length > 0 && (
                    <div className="combo-categories">
                      <Container sx={{ height: "100vh", pt: 25 }}>
                        <DropDownTreeSelect
                          data={categoriesTree}
                          setListId={setListId}
                          listId={listId}
                          onChangeCategories={handleSelectionChangeCategory}
                          clear={clear}
                        />
                      </Container>
                    </div>
                  )}
                  {showFilters && filteredProducts?.length > 0 && (
                    <div className="select-products-filter-wrapper">
                      <FormControl
                        variant="outlined"
                        className="formControl combo_products"
                      >
                        <Autocomplete
                          multiple
                          id="products-select-filled"
                          className="products-autocomplete"
                          options={filteredProducts}
                          disableCloseOnSelect
                          getOptionLabel={option => option.label}
                          onChange={(event, value) =>
                            handleSelectionChangeProduct(event, value)
                          }
                          renderOption={(props, option, { selected }) => {
                            const { key, ...optionProps } = props;
                            return (
                              <li key={option.id} {...optionProps}>
                                <Checkbox
                                  checked={selectedProducts
                                    .map(s => s.id)
                                    .includes(option.id)}
                                />
                                {option.label}
                              </li>
                            );
                          }}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                label={option.label}
                                {...getTagProps({ index })}
                                className="custom-chip"
                              />
                            ))
                          }
                          renderInput={params => (
                            <TextField
                              className="products-textfield"
                              {...params}
                              label=""
                              placeholder="Buscar producto"
                            />
                          )}
                        />
                      </FormControl>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className={`auditoria-filter-panel-buttons`}>
            <button
              className={`auditoria-filter-panel-filter-apply ${
                appliedEnabled ? "" : "disabled"
              }`}
              disabled={!appliedEnabled}
              onClick={() => handleApply(selectedOptions)}
            >
              APLICAR
            </button>
            <button
              className="auditoria-filter-panel-filter-delete"
              onClick={handleDelete}
            >
              BORRAR
            </button>
          </div>

          <div className={`auditoria-filter-categories-products`}>
            {!isMobile &&
              showFilters &&
              categoriesTree &&
              categoriesTree.length > 0 && (
                <div className="categories-tree">
                  <Container sx={{ height: "100vh", pt: 25 }}>
                    <DropDownTreeSelect
                      data={categoriesTree}
                      setListId={setListId}
                      listId={listId}
                      onChangeCategories={handleSelectionChangeCategory}
                      clear={clear}
                    />
                  </Container>
                </div>
              )}

            {!isMobile && showFilters && filteredProducts?.length > 0 && (
              <FormControl
                variant="outlined"
                className="formControl products-autocomplete"
              >
                <Autocomplete
                  multiple
                  id="products-select-filled"
                  options={filteredProducts}
                  disableCloseOnSelect
                  getOptionLabel={option => option.label}
                  onChange={(event, value) =>
                    handleSelectionChangeProduct(event, value)
                  }
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={option.id} {...optionProps}>
                        <Checkbox
                          checked={selectedProducts
                            .map(s => s.id)
                            .includes(option.id)}
                        />
                        {option.label}
                      </li>
                    );
                  }}
                  style={{ width: 500 }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={option.label}
                        {...getTagProps({ index })}
                        className="custom-chip"
                      />
                    ))
                  }
                  renderInput={params => (
                    <TextField
                      className="products-textfield"
                      {...params}
                      label=""
                      placeholder="Buscar producto"
                    />
                  )}
                />
              </FormControl>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AuditoriaFilterPanel;
